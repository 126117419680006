<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <section>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row >
              <b-col cols="12">
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="col-4">
                        <b-row>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="name"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.name')"
                                label-for="name"
                              >
                                <b-form-input
                                  id="name"
                                  v-model="inputData.name"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="order"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.order')"
                                label-for="order"
                              >
                                <b-form-input
                                  id="order"
                                  v-model="inputData.order"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="price"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.price')"
                                label-for="price"
                              >
                                <b-form-input
                                  id="price"
                                  v-model="inputData.price"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="currency"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.currency')"
                                label-for="currency"
                              >
                                <b-form-input
                                  id="currency"
                                  v-model="inputData.currency"
                                  readonly
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="description"
                            >
                              <b-form-group
                                :label="$t('common.general.description')"
                                label-for="description"
                              >
                                <b-form-textarea
                                  id="description"
                                  v-model="inputData.description"
                                  rows="5"
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="note"
                            >
                              <b-form-group
                                :label="$t('common.general.note')"
                                label-for="note"
                              >
                                <b-form-textarea
                                  id="note"
                                  v-model="inputData.note"
                                  rows="5"
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="bestChoice"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.best_choice')"
                                label-for="bestChoice"
                              >
                                <b-form-checkbox
                                  v-model="inputData.bestChoice"
                                  checked="true"
                                  name="check-button"
                                  switch
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="button"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.button')"
                                label-for="button"
                              >
                                <b-form-input
                                  id="button"
                                  v-model="inputData.button"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="buttonLoadMore"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.show_more')"
                                label-for="buttonLoadMore"
                              >
                                <b-form-input
                                  id="buttonLoadMore"
                                  v-model="inputData.buttonLoadMore"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                />

                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <validation-provider
                              #default="validationContext"
                              name="colorButtonLoadMore"
                              rules="required"
                            >
                              <b-form-group
                                :label="$t('common.general.color_show_more')"
                                label-for="colorButtonLoadMore"
                              >
                                <InputColorPicker v-model="inputData.colorButtonLoadMore" />
                                <b-form-input
                                  id="colorButtonLoadMore"
                                  v-model="inputData.colorButtonLoadMore"
                                  :state="getValidationState(validationContext)"
                                  trim
                                  :placeholder="
                                    $t('common.form.please_insert_data')
                                  "
                                /> 
                                <b-form-invalid-feedback>
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="col-8">
                        <b-row
                          v-for="(item, index) in items"
                          :id="item.id"
                          :key="item.id"
                          ref="row"
                        >
                          <!-- Item Name -->
                          <b-col cols="8">
                            <b-form-group
                              :label="$t('common.general.key_name')"
                              label-for="item-name"
                            >
                              <b-form-input
                                id="item-name"
                                v-model="item.value"
                                type="text"
                                name="value"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="2">
                            <b-form-group
                              :label="$t('common.general.show_more')"
                              label-for="show_more"
                            >
                              <b-form-checkbox
                                v-model="item.show_more"
                                checked="true"
                                name="show_more"
                                switch
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="2"
                            class="mb-50"
                          >
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="removeItem(index)"
                            >
                              <feather-icon
                                icon="XIcon"
                                class="mr-25"
                              />
                              <span>Delete</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="repeateAgain"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add New</span>
                        </b-button>
                      </div>

                      <b-col>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="primary"
                          block
                          type="submit"
                        >
                          {{ $t("common.form.save") }}
                        </b-button>
                      </b-col>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
    </b-overlay>
    <ListStepsPage :package-i-d="id" />
  </div>
</template>

<script>
import InputColorPicker from 'vue-native-color-picker'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BOverlay,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import packageServices from '@/services/packageServices';
import commonServices from '@/services/commonServices';
import ListStepsPage from '@/views/pages/package/ListStepsPage.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    InputColorPicker,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BOverlay,
    BFormTextarea,
    ListStepsPage,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.query.id,
      inputData: {},
      required,
      items: [],
    };
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  created() {
    if (this.id) {
      this.getDetails();
    }
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.inputData.features = JSON.stringify(this.items);
      await packageServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({ name: 'list-packages' });
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getDetails() {
      this.isLoading = true;
      await packageServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getPackage;
            if (this.inputData.features) {
              this.items = JSON.parse(this.inputData.features);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    repeateAgain() {
      this.items.push({
        id: commonServices.uuid(),
        value: '',
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>
