/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getStepPackage,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async createUpdate(input) {
    if (!input.id) {
      return API.graphql({
        query: mutations.createStepPackage,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
    } 
    delete input.package
    delete input.createdAt
    delete input.updatedAt
    delete input.owner
    return API.graphql({
      query: mutations.updateStepPackage,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async getList(id) {
    return API.graphql({
      query: queries.listStepPackages,
      variables: {
        filter: {
          packageID: {
            eq: id,
          },
        }, 
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  }, 

  async delete(id) {
    return API.graphql({
      query: mutations.deleteStepPackage,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },

}
