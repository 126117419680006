<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <h3>{{ $t("common.general.details") }}</h3>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="onAdd()"
                >
                  <span class="text-nowrap">{{ $t("common.form.add") }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refPageListTable"
          class="position-relative"
          :items="dataList"
          :fields="tableColumns"
          :busy="isLoadingTable"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('common.general.empty_text')"
        >
          <template #cell(actions)="data">
            <b-row>
              <b-col><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                size="sm"
                variant="primary"
                @click="getDetails(data.item.id)"
              >{{ $t("common.form.edit") }}</b-button></b-col>
            </b-row>
          </template>
          <template #table-busy>
            <TableLoading />
          </template>
        </b-table>
      </b-card>
    </b-col>

    <b-col
      v-if="isAddEdit"
      cols="12"
    >
      <b-card
        no-body
        class="mt-2"
      >
        <b-card-body>
          <b-overlay
            id="overlay-background"
            :show="isLoadingDetails"
            :variant="`transparent`"
            :opacity="0.5"
            :blur="`10px`"
            rounded="sm"
            spinner-type="grow"
          >
            <section>
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-row>
                    <b-col cols="12">
                      <validation-provider
                        #default="validationContext"
                        name="step"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.step')"
                          label-for="step"
                        >
                          <b-form-input
                            id="step"
                            v-model="inputData.step"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        #default="validationContext"
                        name="summary"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.summary')"
                          label-for="summary"
                        >
                          <b-form-textarea
                            id="description"
                            v-model="inputData.summary"
                            rows="5"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(item, index) in items"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >
                    <!-- Item Name -->
                    <b-col cols="7">
                      <b-form-group
                        :label="$t('common.general.key_name')"
                        label-for="item-name"
                      >
                        <b-form-input
                          id="item-name"
                          v-model="item.value"
                          type="text"
                          name="value"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group 
                        :label="$t('common.general.align_right')"
                        label-for="item-right"
                      >
                        <b-form-checkbox
                          v-model="item.right"
                          checked="true"
                          name="right" 
                          switch
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Delete</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeateAgain"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add New</span>
                      </b-button>
                    </b-col>
                    <b-col cols="6">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        block
                        type="submit"
                      >
                       {{ $t("common.form.save") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </section>
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import stepPackageServices from '@/services/stepPackageServices';
import {
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  VBModal,
  BOverlay,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import commonServices from '@/services/commonServices';
import TableLoading from '@/components/TableLoading.vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { heightTransition } from '@core/mixins/ui/transition';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BFormCheckbox,
    BButton,
    BCol,
    BRow,
    TableLoading,
    BOverlay,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    packageID: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      isAddEdit: false,
      itemSelected: {},
      itemSelectedDelete: {},
      dataList: [],
      perPage: 10,
      perPageOptions: [10],
      isLoadingTable: false,
      isLoadingDetails: false,
      inputData: {},
      items: [],
      sortBy: 'step',
      sortDesc: false,
      tableColumns: [
        { key: 'step', label: this.$t('common.general.step'), sortable: true },
        {
          key: 'summary',
          label: this.$t('common.general.summary'), 
        },
        { key: 'actions', label: this.$t('common.form.actions') },
      ],
    };
  },
  created() {
    this.getList();
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  mounted() {},

  methods: {
    async getList() {
      this.isLoadingTable = true;
      await stepPackageServices
        .getList(this.packageID)
        .then((result) => {
          if (result) {
            this.dataList = result.data.listStepPackages.items;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingTable = false;
        });
    },
    formatDatetime(val) {
      return commonServices.formatDatetime(val);
    },
    onAdd() {
      this.isAddEdit = true;
      this.inputData = {};
      this.items = [];
    },
    async onSubmit() {
      this.isLoadingDetails = true;
      this.inputData.packageID = this.packageID;
      this.inputData.features = JSON.stringify(this.items);
      await stepPackageServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.getList();
              this.isAddEdit = false;
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoadingDetails = false;
        });
    },
    async getDetails(id) {
      this.isAddEdit = true;
      this.isLoadingDetails = true;
      await stepPackageServices
        .getDetail(id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getStepPackage;
            if (this.inputData.features) {
              this.items = JSON.parse(this.inputData.features);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingDetails = false;
        });
    },
    repeateAgain() {
      this.items.push({
        id: commonServices.uuid(),
        right: false,
        value: '',
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
